import React, { useRef } from "react";
import Draggable from "react-draggable";
import { capitalizeFirstLetter } from "../../action";

const DeletePopup = ({ file, type, onDelete, onCancel }) => {
  const divRef = useRef(null);
  return (
    <Draggable nodeRef={divRef}>
      <div className="deleteDialog" ref={divRef}>
        <div className="deleteDialogTitle">
          <span id="ui-id-1" className="ui-dialog-title">
            Confirm Delete?
          </span>
        </div>
        <div
          id="dialog-confirm"
          className="ui-dialog-content ui-widget-content"
        >
          <div className="FileContent" id="dialog-message">
            Are you sure you want to delete this {type}?
            <ul>
              <li>
                <b>{capitalizeFirstLetter(type)} : </b> {file.fileName}
              </li>
              <li>
                <b>Uploaded By : </b> {file.author}
              </li>
            </ul>
          </div>
        </div>
        <div className="ui-dialog-buttonpane ">
          <div className="ui-dialog-buttonset">
            <button
              type="button"
              className="btn  btn-primary waves-effect bck-btn"
              onClick={onDelete}
            >
              <i class="zmdi zmdi-delete"></i>
              Delete
            </button>
            <button
              type="button"
              className="btn  btn-primary waves-effect bck-btn"
              onClick={onCancel}
            >
              <i class="zmdi zmdi-close"></i>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default DeletePopup;
