import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import "./usermanagement.css";
import { useHistory } from "react-router-dom";
import { updateUserDetail } from "../../action";
import {
  CLEAR_ALL,
  EDIT_USER_STATUS,
  IS_TOKEN_EXPIRED,
} from "../../action/type";

export const EditUsers = () => {
  const [editPayload, setEditPayload] = useState({});
  const [roleList, setRoleList] = useState([]);
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const userID = useSelector((state) => state.token.userID);

  useEffect(() => {
    if (params.userId && history?.location?.state) {
      let editUserData = history?.location?.state?.user
        ? history.location.state.user
        : {};
      let roleList = history?.location?.state?.roleList
        ? history.location.state.roleList
        : [];
      setEditPayload(editUserData);
      setRoleList(roleList);
    } else {
      history.push("/user/manage");
    }
  }, [history]);

  const onChangeHandler = (e) => {
    let data = { ...editPayload };
    data.roleID = e.target.value;
    setEditPayload(data);
  };
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const { id, roleID } = editPayload;
    let data = {
      modifiedby: Number(userID),
      id: Number(id),
      roleid: Number(roleID),
    };
    let res = await updateUserDetail(data);
    dispatch({ type: EDIT_USER_STATUS, payload: res.data.statusMessage });
    setTimeout(() => {
      dispatch({ type: EDIT_USER_STATUS, payload: "" });
    }, 5000);
    if (res.data.statusCode == 200) {
      history.push("/user/manage");
    } else {
      if (res.status === 403) {
        dispatch({ type: IS_TOKEN_EXPIRED });
        setTimeout(() => dispatch({ type: CLEAR_ALL }), 3000);
      } else {
        alert(res.response.data.message);
      }
    }
  };

  const { email_id = "", name = "", isActive = 0, roleID } = editPayload;
  return (
    <section id="main" className="layout-1">
      <div className="container-fluid">
        <div className="card-body">
          <div className="tab-content p-0">
            <section id="content" className="p-l-0">
              <div className="container-fluid">
                <div className="row row p-relative">
                  <div className="col-xs-12 col-sm-8">
                    <span className="label_span">Edit User</span>{" "}
                  </div>
                  <div className="col-xs-12 col-sm-4 text-right">
                    <a
                      className="btn  btn-primary waves-effect bck-btn add-btn"
                      href="/user/manage"
                    >
                      <i
                        style={{ color: "white" }}
                        className="zmdi zmdi-long-arrow-left"
                      ></i>
                      Back
                    </a>
                  </div>
                </div>

                <div className="card m-t-5 mb-0">
                  <div className="card-header p-15 pb-0 header-button">
                    <div className="card-body">
                      <div className="doclist-cont">
                        <div className="list-group m-b-10">
                          <div className="row">
                            <div className="col-sm-12">
                              <form
                                id="userDto"
                                className="user-form"
                                autoComplete="false"
                              >
                                <div className="row user-form-box">
                                  <div className="col-9">
                                    <div className="row">
                                      <div className="col-5">
                                        <div className="labels">
                                          User Name
                                          <span className="mandatory">*</span>
                                        </div>
                                        <input
                                          value={name}
                                          id="name"
                                          name="name"
                                          className="form-control disabled"
                                          placeholder="Enter user name"
                                          type="text"
                                          autoComplete="false"
                                          readOnly
                                          disabled
                                        />
                                      </div>
                                      <div className="col-5">
                                        <div className="labels">
                                          Email Id
                                          <span className="mandatory">*</span>
                                        </div>
                                        <input
                                          value={email_id}
                                          id="email"
                                          name="username"
                                          className="form-control disabled"
                                          placeholder="Enter email Id"
                                          type="text"
                                          readOnly
                                          disabled
                                        />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-5">
                                        <div className="labels">
                                          Role
                                          <span className="mandatory">*</span>
                                        </div>

                                        <select
                                          id="role"
                                          name="role"
                                          className="form-control"
                                          onChange={onChangeHandler}
                                          value={roleID}
                                        >
                                          {roleList?.map((role) => (
                                            <option
                                              value={role.id}
                                              key={role.id}
                                            >
                                              {role.name}
                                            </option>
                                          ))}
                                        </select>
                                        <input
                                          type="hidden"
                                          className="form-control"
                                          id="currentRole"
                                          value="3"
                                        />
                                      </div>

                                      <div className="col-5">
                                        <div className="labels">Status</div>
                                        <div className="checkbox">
                                          <label>
                                            <input
                                              disabled
                                              className="disabled"
                                              id="valid1"
                                              name="valid"
                                              type="checkbox"
                                              value="true"
                                              checked={
                                                isActive ? "checked" : ""
                                              }
                                            />
                                            <input
                                              type="hidden"
                                              name="_valid"
                                              value="on"
                                            />
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-2 form-sumbmit-area ">
                                        <button
                                          type="submit"
                                          className="btn  btn-primary waves-effect bck-btn mr-1 save_btn"
                                          onClick={onSubmitHandler}
                                        >
                                          <i
                                            class="fa fa-floppy-o"
                                            aria-hidden="true"
                                          ></i>
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};
