import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunkMiddelware from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducer";

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


  export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunkMiddelware)))
  export const persistor = persistStore(store)


// export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunkMiddelware)));