import AWS from 'aws-sdk';

let bucketName = process.env.REACT_APP_BUCKET_NAME;
let bucketRegion = process.env.REACT_APP_BUCKET_REGION;
let IdentityPoolId = process.env.REACT_APP_IDENTITY_POOLID;

AWS.config.update({
  region: bucketRegion,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IdentityPoolId,
  }),
});

export const S3 = new AWS.S3({
  apiVersion: '2006-03-01',
  params: { Bucket: bucketName },
});

export const bucket = bucketName
