import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createNewFolder } from "../../action";
import Modal from "../Modal/Modal";

const CreateFolder = (props) => {
  const [folderName, setFolderName] = useState("New Folder");
  const currentPath = useSelector((state) => state.currentPath);
  const currentPathObj = useSelector((state) => state.currentPathObj);
  const listContent = useSelector((state) => state.listContent);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    setFolderName(e.target.value);
  };

  const createNewDoc = (e) => {
    e.preventDefault();
    let pattern = new RegExp(`^[^<>:"./\\|~?*]+$`);
    let folderExist = listContent
      .filter((content) => content.type === "folder")
      .find((folder) => folder.name.toLowerCase() === folderName.toLowerCase());
    let path = currentPath
      ? currentPath + "/" + folderName + "/"
      : folderName + "/";
    let id = currentPathObj[0] ? currentPathObj[0].folderID : "0";
    if (folderExist) setShowAlert(true);
    else if (!folderName) alert("Document name can not be blank.");
    else if (!pattern.test(folderName))
      alert('< > : / " \\ | ? ~ . *  are not allowed in folder name.');
    else {
      dispatch(createNewFolder(path, id));
      props.closeForm(true);
    }
  };

  return (
    <div className="add-folder-cont">
      <div className="row">
        <div className="col-sm-6">
          <form id="new-folder-form">
            <div className="row">
              <div className="col-sm-10">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    name="folderName"
                    pattern='^[^<>:"./\\|~?*]+$'
                    id="new_folder_name"
                    value={folderName}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                  <div className="input-group-addon">
                    <button
                      className="btn btn-primary waves-effect create-btn"
                      onClick={createNewDoc}
                    >
                      <i class="zmdi zmdi-folder-star"></i>Create
                    </button>
                    <button
                      className="add-folder-cont-hide btn btn-primary waves-effect m-l-7 m-r-1"
                      onClick={props.closeForm}
                    >
                      <i class="zmdi zmdi-close"></i> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {showAlert ? (
        <Modal>
          <div className="deleteDialog alertDialog">
            <div className="deleteDialogTitle">
              <span id="ui-id-1" className="ui-dialog-title">
                Alert!
              </span>
            </div>
            <div
              id="dialog-confirm"
              className="ui-dialog-content ui-widget-content"
            >
              <p>
                <span id="dialog-message">Folder name already exists.</span>
              </p>
            </div>
            <div className="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
              <div className="ui-dialog-buttonset">
                <button type="button" onClick={() => setShowAlert(false)}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default CreateFolder;
