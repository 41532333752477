import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getListContent, moveFile } from "../../action";
import Modal from "../Modal/Modal";
import $ from "jquery";
import { IS_MOVE_STATUS, CLEAR_STATUS } from "../../action/type";

const MovePopup = (props) => {
  const dispatch = useDispatch();

  const [folder, setFolder] = useState("");
  const [folderPath, setFolderPath] = useState("");
  const [isNewFolder, setIsNewFolder] = useState(false);
  const [destId, setDestId] = useState("");
  const [update, setUpdate] = useState(true);
  const [isMove, setMove] = useState(false);
  const [moveStatusMsg, setMoveStatus] = useState("");

  const listFolders = useSelector((state) => state.listFolders);
  const moveReducer = useSelector((state) => state.isMoveFileFolder);
  const { isSuccess, statusMsg } = moveReducer;

  useEffect(() => {
    if (!isSuccess && statusMsg) {
      setMove(true);
      setMoveStatus(statusMsg);
    }

    return () => {
      if (isSuccess) {
        props.onClose();
        dispatch(getListContent(props.movingItem.parentDir));

        setTimeout(() => {
          dispatch({ type: CLEAR_STATUS, payload: "" });
          setMove(false);
          setMoveStatus("");
        }, 4000);
      }
    };
  }, [statusMsg, isSuccess]);

  useEffect(() => {
    if (isNewFolder) {
      setFolderPath(folder);
      setIsNewFolder(false);
    } else if (folderPath != folder && !isNewFolder) {
      setFolderPath(folderPath + "/" + folder);
    }
  }, [isNewFolder, folder]);

  useEffect(() => {
    if (listFolders && update) {
      setUpdate(false);
      $(".treeviewdoc").on(
        "change",
        "input[type='checkbox']",
        function (event) {
          $(".selectedfolder").removeClass("selectedfolder");
          $(this).closest("ul").find(".myCustomId").remove();
          var rowElement = $(this).parent();
          $(rowElement).addClass("selectedfolder");
          var myId = $(this)[0].id;
          var ObjUl1 = $('<ul class= "myCustomId"></ul>');
          let childList = listFolders.filter((i) => i.parentDir == myId);
          let childData = childList.filter(
            (itm) => itm.id !== props.movingItem.id
          );

          if ($("#" + myId).prop("checked") == true) {
            //$(this).closest("ul .myCustomId").remove();
            $(this)
              .closest("ul")
              .find("input[type='checkbox']")
              .not(this)
              .prop("checked", false);
            for (var i = 0; i < childData.length; i++) {
              if (myId == childData[i].parentDir) {
                var Objli1 = $('<li id="child' + childData[i].id + '"></li>');
                var Objchk1 = $(
                  '<input type="checkbox" id="' + childData[i].id + '"/>'
                );
                var Objlbl1 = $(
                  '<label class="' +
                    childData[i].folderName +
                    '" for="' +
                    childData[i].id +
                    '"></label>'
                );

                Objlbl1.text(childData[i].folderName);

                Objli1.append(Objchk1, Objlbl1);

                ObjUl1.append(Objli1);
              } else {
              }
            }
            var childId = $("#" + myId)
              .parent()
              .get(0).id;
            setDestId(childId.match(/(\d+)/)[0]);
            setFolder(event.target.labels[0].innerText);
            childId.includes("parent") && setIsNewFolder(true);
            $(ObjUl1).insertAfter("#" + childId);
          } else {
            $(this).parent().next(".myCustomId").remove();
          }
        }
      );
    }
  }, [listFolders]);

  let contents = listFolders
    ? listFolders.filter(
        (i) => i.parentDir === 0 && i.id != props.movingItem.id
      )
    : [];

  const moveDocument = () => {
    if (destId == props.movingItem.parentDir) {
      setMove(true);
      setMoveStatus("Source and  Destination Directory can not be same.");
    } else {
      dispatch(
        moveFile(
          destId,
          props.movingItem.type,
          props.movingItem.id,
          props.movingItem.parentDir
        )
      );
    }
  };

  const onCloseAlert = () => {
    setMove(false);
    dispatch({ type: IS_MOVE_STATUS, payload: "" });
  };

  return (
    <>
      <div
        className="modal app-model fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        id="moveCopyPopup"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content" id="shareModalPopup-content">
            <div className="modal-header">
              <button
                type="button"
                className="close "
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => props.onClose()}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h3
                className="modal-title"
                id="myModalLabel"
                style={{ color: "white" }}
              >
                Move
              </h3>
            </div>
            <div className="modal-body">
              <div className="tree-box">
                <div className="treeview" id="customIDD">
                  <ul className="doct selectedfolder">
                    <li onClick={() => setDestId(0)}>
                      <input type="checkbox" id="0" />
                      <label>Document</label>
                    </li>
                  </ul>
                  <div className="treeviewdoc myCustomId">
                    {/* {props.listFolders ? <FileBrowser files={props.listFolders}/> : null} */}
                    {
                      <ul>
                        {contents.map((i, key) => {
                          return (
                            <li id={`parent${i.id}`} key={key}>
                              <input type="checkbox" id={i.id} />
                              <label htmlFor={i.id}>{i.folderName}</label>
                            </li>
                          );
                        })}
                      </ul>
                    }
                  </div>
                </div>
              </div>
              <div id="dialog-confirm-replace" style={{ display: "none" }}>
                <p>
                  <span
                    className="ui-icon ui-icon-alert"
                    style={{ float: "left", margin: "0 7px 20px 0" }}
                  ></span>
                  Document with same name already exist. Do you want to replace
                  it?
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                id="move-button"
                className="btn  btn-primary waves-effect bck-btn"
                sourcetype="directory"
                onClick={moveDocument}
              >
                <i class="zmdi zmdi-square-right"></i>
                Move
              </button>
              <button
                type="button"
                data-dismiss="modal"
                className="btn  btn-primary waves-effect bck-btn"
                onClick={() => props.onClose()}
              >
                <i class="zmdi zmdi-close"></i>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      {isMove && (
        <Modal>
          <div className="deleteDialog alertDialog">
            <div className="deleteDialogTitle">
              <span id="ui-id-1" className="ui-dialog-title">
                Alert!
              </span>
            </div>
            <div
              id="dialog-confirm"
              className="ui-dialog-content ui-widget-content"
            >
              <p>
                <span id="dialog-message">{moveStatusMsg}</span>
              </p>
            </div>
            <div className="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
              <div className="ui-dialog-buttonset">
                <button type="button" className="btn  btn-primary waves-effect bck-btn" onClick={() => onCloseAlert()}>
                <i class="zmdi zmdi-close"></i> Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default MovePopup;
