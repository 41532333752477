export const LIST_CONTENT = "LIST_CONTENT";
export const SET_CURRENT_PATH = "SET_CURRENT_PATH";
export const LIST_FOLDERS = "LIST_FOLDERS";
export const SET_CURRENT_PATH_OBJ = "SET_CURRENT_PATH_OBJ"
export const isFETCHING_DATA = "isFETCHING_DATA"
export const FETCHING_COMPLETED = "FETCHING_COMPLETED"
export const isFETCHING_DATA_FOLDERS = "isFETCHING_DATA_FOLDERS"
export const FETCHING_COMPLETED_FOLDERS = "FETCHING_COMPLETED_FOLDERS"
export const GENERATE_STATE_AND_NONCE = "GENERATE_STATE_AND_NONCE"
export const RECEIVE_ACCESS_TOKEN = "RECEIVE_ACCESS_TOKEN"
export const RECEIVE_ID_TOKEN = "RECEIVE_ID_TOKEN"
export const RECEIVE_PROFILE_DATA = "RECEIVE_PROFILE_DATA"
export const CLEAR_ALL = "CLEAR_ALL"
export const STATUS_MSG = "STATUS_MSG"
export const IS_AUTHORIZED = "IS_AUTHORIZED"
export const ROLE_ID = "ROLE_ID"
export const VERSION = "VERSION"
export const IS_TOKEN_EXPIRED = "IS_TOKEN_EXPIRED"
export const IS_MOVE_STATUS = "IS_MOVE_STATUS"
export const CLEAR_STATUS = "CLEAR_STATUS"
export const USER_LIST = "USER_LIST"
export const ROLE_LIST = "ROLE_LIST"
export const LOGGED_IN_USER_ID = "LOGGED_IN_USER_ID"
export const EDIT_USER_STATUS = "EDIT_USER_STATUS"
export const CLEAR_MSG = "CLEAR_MSG"




