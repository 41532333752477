import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { downloadFile, deleteFile, getPopupContent } from "../../action";
import MovePopup from "./MovePopup";
import Modal from "../Modal/Modal";
import { useTable, usePagination, useFilters, useSortBy } from "react-table";
import Loader from "../Loader";
import DeletePopup from "./DeletePopup";

const TableSection = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [movingItem, setMovingItem] = useState({});
  const [filterInput, setFilterInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteIdType, setDeleteIdType] = useState({});
  const isFetching = useSelector((state) => state.isFetching);
  const isFetchingFolder = useSelector((state) => state.isFetchingFolder);
  const { isSuccess } = useSelector((state) => state.isMoveFileFolder);
  const userRoleId = useSelector((state) => state.token.roleID);

  const history = useHistory();
  const dispatch = useDispatch();

  let { columns, data } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    setFilter,
    filteredRows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 25,
        hiddenColumns: ["itemId", "type", "path", "parentDir", "isEmpty"],
        // sortBy : [{ id: "date"}]
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );
  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    setFilter("name", value);
    setFilterInput(value);
  };

  const onClickFunction = (cell) => {
    return {
      onClick: (e) => {
        if (cell.column.id === "name") {
          if (cell.row.values.type === "folder") {
            props.closeForm();
            let id = cell.row.values.itemId;
            // dispatch(getListContent(id))
            history.push(`/document/manage?id=${id}`);
          }
        }
        if (cell.column.id === "down" && cell.row.values.type != "folder") {
          dispatch(downloadFile(cell.row.values.path));
        }

        if (cell.column.id === "move" && (userRoleId == 1 || userRoleId == 2)) {
          setShowPopup(true);
          document.querySelector("body").classList.add("modal-open");
          dispatch(getPopupContent());
          if (cell.row.values.type === "folder")
            setMovingItem({
              name: cell.row.values.name + "/",
              type: "folder",
              id: cell.row.values.itemId,
              parentDir: cell.row.values.parentDir,
            });
          else
            setMovingItem({
              name: cell.row.values.name,
              type: "file",
              id: cell.row.values.itemId,
              parentDir: cell.row.values.parentDir,
            });
        }
        if (
          cell.column.id === "delete" &&
          (userRoleId == 1 || userRoleId == 2)
        ) {
          if (
            cell.row.values.type == "folder" &&
            cell.row.values.isEmpty == 0
          ) {
            return;
          } else {
            setShowDelete(true);
            setDeleteIdType({
              id: cell.row.values.itemId,
              type: cell.row.values.type,
              parentDir: cell.row.values.parentDir,
              fileName: cell.row.values.name,
              author: cell.row.values.created,
            });
          }
        }
      },
    };
  };

  const getPageList = (totalPages, page, maxLength) => {
    if (maxLength < 5) throw "maxLength must be at least 5";

    function range(start, end) {
      return Array.from(Array(end - start + 1), (_, i) => i + start);
    }

    var sideWidth = maxLength < 9 ? 1 : 2;
    var leftWidth = (maxLength - sideWidth * 2 - 3) >> 1;
    var rightWidth = (maxLength - sideWidth * 2 - 2) >> 1;
    if (totalPages <= maxLength) {
      // no breaks in list
      return range(1, totalPages);
    }
    if (page <= maxLength - sideWidth - 1 - rightWidth) {
      // no break on left of page
      return range(1, maxLength - sideWidth - 1).concat(
        0,
        range(totalPages - sideWidth + 1, totalPages)
      );
    }
    if (page >= totalPages - sideWidth - 1 - rightWidth) {
      // no break on right of page
      return range(1, sideWidth).concat(
        0,
        range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages)
      );
    }
    // Breaks on both sides
    return range(1, sideWidth).concat(
      0,
      range(page - leftWidth, page + rightWidth),
      0,
      range(totalPages - sideWidth + 1, totalPages)
    );
  };

  const folderFileType = (cell) => {
    return cell.column.id === "name"
      ? cell.row.values.type === "file"
        ? "file_name"
        : "folder_name"
      : "";
  };

  const folderFileIcon = (cell) => {
    let fileType = cell.column.id === "name" && cell.value.split(".");

    let type =
      cell.column.id === "name" &&
      cell.value.includes(".") &&
      fileType[fileType.length - 1];
    return cell.column.id === "name"
      ? cell.row.values.type === "file"
        ? type === "pdf" || type === "PDF"
          ? "fa  fa-file-pdf-o pdf-icon"
          : type === "doc" || type === "docx"
          ? "fa fa-file-word-o word"
          : type === "zip" || type === "rar"
          ? "fa fa-file-zip-o zip"
          : type === "xls" || type === "xlsx" || type === "xlsm"
          ? "fa fa-file-excel-o excel-icon"
          : type === "pptx" || type === "ppt" || type === "pps"
          ? "fa fa-file-powerpoint-o powerpoint-icon"
          : ""
        : "fa fa-folder-open-o folder"
      : cell.column.id === "down" && cell.row.values.type === "file"
      ? "zmdi zmdi-download download p-l-10"
      : cell.column.id === "move" && (userRoleId == 1 || userRoleId == 2)
      ? "zmdi zmdi-arrows"
      : cell.column.id === "delete" && (userRoleId == 1 || userRoleId == 2)
      ? cell.row.values.type === "folder"
        ? cell.row.values.isEmpty
          ? "fa fa-trash"
          : ""
        : "fa fa-trash"
      : "";
  };

  const onClose = () => {
    setShowPopup(false);
    document.querySelector("body").classList.remove("modal-open");
  };

  let items = getPageList(pageCount, currentPage, 7).map((item, key) => {
    return (
      <button
        key={key}
        className={`${
          currentPage === item ? "selectedButton" : item === 0 ? "noUse" : ""
        }`}
        onClick={() => {
          item && setCurrentPage(item);
          item && gotoPage(item - 1);
        }}
      >
        {item === 0 ? "..." : item}
      </button>
    );
  });

  useEffect(() => {
    currentPage === pageCount ? setCurrentPage(pageCount) : setCurrentPage(1);
  }, [pageCount]);

  if (isFetching) return <Loader />;
  else
    return (
      <>
        <div className="dataTables_length" id="myTable_length">
          <label>
            Show&nbsp;
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                gotoPage(0);
                if (currentPage === pageCount) setCurrentPage(pageCount);
              }}
            >
              {[10, 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div id="myTable_filter" className="dataTables_filter">
          <label>
            Search:
            <input
              type="search"
              placeholder=""
              value={filterInput}
              onChange={handleFilterChange}
              aria-controls="myTable"
            />
          </label>
        </div>
        <div className="scrollableElement">
          <table
            {...getTableProps({
              id: "myTable",
              className:
                "display dataTable dtr-inline table electronics-table scroll-container mCustomScrollbar",
            })}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {
              <tbody {...getTableBodyProps()}>
                {page.map((row, ind) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps({
                        id: "child_${childDocument.id}",
                        className: `${ind % 2 != 0 ? "even" : "odd"}`,
                      })}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps([
                              {
                                className: `${folderFileType(cell)} ${
                                  cell.column.isSorted
                                    ? ind % 2 != 0
                                      ? "column-sorted-even"
                                      : "column-sorted-odd"
                                    : ""
                                }`,
                              },
                              onClickFunction(cell),
                              // props.getCellProps(cell)
                            ])}
                          >
                            {folderFileIcon(cell) ? (
                              <i
                                title={
                                  cell.column.id == "down"
                                    ? "Download"
                                    : cell.column.id == "move"
                                    ? "Move"
                                    : cell.column.id == "delete"
                                    ? "Delete"
                                    : ""
                                }
                                className={folderFileIcon(cell)}
                              ></i>
                            ) : null}
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            }
          </table>
        </div>

        <div className="showRecord">
          {filterInput ? (
            <span>
              Showing {pageIndex * pageSize + 1} to{" "}
              {(pageIndex + 1) * pageSize < filteredRows.length
                ? (pageIndex + 1) * pageSize
                : filteredRows.length}{" "}
              of {filteredRows.length} entries (filtered from {data.length}{" "}
              total enteries)
            </span>
          ) : (
            <span>
              Showing {pageIndex * pageSize + 1} to{" "}
              {(pageIndex + 1) * pageSize < data.length
                ? (pageIndex + 1) * pageSize
                : data.length}{" "}
              of {data.length} entries
            </span>
          )}
        </div>
        <div className="pagination">
          <button
            onClick={() => {
              setCurrentPage(currentPage - 1);
              previousPage();
            }}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          <span>{items}</span>
          <button
            onClick={() => {
              setCurrentPage(currentPage + 1);
              nextPage();
            }}
            disabled={!canNextPage}
          >
            Next
          </button>
        </div>
        {showPopup ? (
          <Modal>
            {isFetchingFolder ? (
              <Loader />
            ) : (
              <MovePopup onClose={onClose} movingItem={movingItem} />
            )}
          </Modal>
        ) : null}
        {showDelete ? (
          <Modal>
            <DeletePopup
              file={deleteIdType}
              type={deleteIdType.type}
              onDelete={() => dispatch(deleteFile(deleteIdType))}
              onCancel={() => setShowDelete(false)}
            />
          </Modal>
        ) : null}
      </>
    );
};

export default TableSection;
