import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Modal from "../Modal/Modal";
import Loader from "../Loader";
import "./Header.css";

const Header = (props) => {
  const [showAbout, setShowAbout] = useState(false);
  const userProfile = useSelector((state) => state.profile);
  const version = useSelector((state) => state.version);
  const userRoleId = useSelector((state) => state.token.roleID);
  const isAuthorized = useSelector((state) => state.token.isAuthorized);

  const history = useHistory();

  const onRedirectHandler = (type = "") => {
    history.push("/user/manage");
  };

  if (!props.loggedIn) {
    return <Loader />;
  }
  return (
    <div>
      <header id="header" className="clearfix">
        <ul className="header-inner ">
          <li className="logo" onClick={() => history.push(`/document/manage`)}>
            <div className="mCS_img_loaded"></div>
            SWR Electronic Data Portal
          </li>
          {props.loggedIn ? (
            <>
              <li className="pull-right">
                <ul className="top-menu ">
                  <li className="dropdown">
                    <a data-toggle="dropdown" href="">
                      <i className="addFolder tm-icon zmdi zmdi-more-vert"></i>
                    </a>
                    <ul className="dropdown-menu dm-icon pull-right">
                      {isAuthorized && userRoleId == 1 && (
                        <li onClick={() => onRedirectHandler("user")}>
                          <i className="zmdi zmdi-accounts-alt"></i> User
                          Management
                        </li>
                      )}
                      <li onClick={() => setShowAbout(true)}>
                        <i
                          class="fa fa-info-circle about-icon"
                          aria-hidden="true"
                        ></i>
                        {/* <i className="about-icon"></i> */}
                        About
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="pull-right welcometext p-r-10">
                <small>Welcome</small>
                <h2 style={{ fontSize: "16px", color: "#fff" }}>
                  {userProfile.fullname}
                </h2>
              </li>
            </>
          ) : (
            <li className="pull-right">
              <Link to="/login">Login</Link>
            </li>
          )}
        </ul>
      </header>
      {showAbout ? (
        <Modal>
          <div className="modal-content aboutModal">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={() => setShowAbout(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title">About</h4>
            </div>
            <div className="about-logo"></div>
            <div className="modal-body text-center">
              <p>
                SWR Electronic Data Portal V{version} has been developed by
                Mistral Data.
              </p>
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default Header;
