import React from 'react';
import { createPortal } from 'react-dom';
import "./Modal.css";

const modalRoot = document.getElementById( 'modal' );

function Modal({ children, parent, className }) {
  const element1 = React.useMemo(() => document.createElement("div"), []);
  element1.classList.add("modal-background")
  const element = React.useMemo(() => document.createElement("div"), []);
  element.classList.add("modal-container")

  React.useEffect(() => {
    modalRoot.appendChild(element1)
    modalRoot.appendChild(element);
    return () => {
      modalRoot.removeChild(element);
      modalRoot.removeChild(element1)
    };
  }, [element, element1, parent, className]);
  return createPortal(children, element);
}

export default Modal;