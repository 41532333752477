import React from 'react'

function Default() {
  return (
    <div>
      404 Page not found
    </div>
  )
}

export default Default
