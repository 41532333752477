import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getListContent, createNewFile, createNewFolder } from "../../action";
import Modal from "../Modal/Modal";

const NavigationSection = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  const [fileMessage, setFileMessage] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const dispatch = useDispatch();
  const currentPath = useSelector((state) => state.currentPath);
  const currentPathObj = useSelector((state) => state.currentPathObj);
  const listContent = useSelector((state) => state.listContent);
  const moveReducer = useSelector((state) => state.isMoveFileFolder);
  const statusMessagesReducer = useSelector((state) => state.statusMessages);

  const { isSuccess, statusMsg = "" } = moveReducer;
  const fileInput = useRef();
  let currentPathArr = currentPath.split("/");

  useEffect(() => {
    setSuccessMsg(statusMsg);
  }, [statusMsg]);

  const onIconClick = (e, item) => {
    // dispatch(getListContent(item.folderID));
    props.history.push(`/document/manage/?id=${item.folderID}`);
  };

  const onBackClick = () => {
    // dispatch(getListContent(currentPathObj[0].par_dir_id))
    currentPathObj[0].par_dir_id
      ? props.history.push(
          `/document/manage/?id=${currentPathObj[0].par_dir_id}`
        )
      : props.history.push(`/document/manage`);
  };

  const handleFileUpload = (e) => {
    const uploadFiles = Array.from(fileInput.current.files);

    let extCheck = uploadFiles.filter((file) => {
      let ext = file.name.split(".").pop();
      return ext == "pdf" ||
        ext == "PDF" ||
        ext == "doc" ||
        ext == "DOC" ||
        ext == "docx" ||
        ext == "DOCX" ||
        ext == "xls" ||
        ext == "XLS" ||
        ext == "xlsx" ||
        ext == "XLSX" ||
        ext == "pptx" ||
        ext == "PPTX" ||
        ext == "ppt" ||
        ext == "PPT" ||
        ext == "xlsm" ||
        ext == "xlsm" ||
        ext == "pps" ||
        ext == "PPS" ||
        ext == "ZIP" ||
        ext == "zip" ||
        ext == "rar" ||
        ext == "RAR"
        ? false
        : true;
    });

    let files = listContent
      .filter((content) => content.type === "file")
      .map((file) => file.name);
    let fileExist = uploadFiles.filter((file) => files.includes(file.name));
    let path = currentPath ? currentPath + "/" : "";
    let id = currentPathObj[0] ? currentPathObj[0].folderID : "0";
    if (fileExist.length) {
      setShowAlert(true);
      setFileMessage("File name already exists.");
      fileInput.current.value = "";
    } else if (extCheck.length) {
      setShowAlert(true);
      setFileMessage(
        "Invalid file format. Please upload excel, pdf, zip, powerpoint or word files only."
      );
      fileInput.current.value = "";
    } else dispatch(createNewFile(path, id, fileInput.current.files));
  };
  const documentPath = [].concat(currentPathObj).reverse();
  return (
    <div>
      <div className="card-body">
        <div
          id="failMsgDiv"
          className="alert alert-danger alert-dismissible"
          style={{ display: "none" }}
          role="alert"
        >
        </div>
        
        {statusMessagesReducer?.message && (
          <div
            id="successMsgDiv"
            className="alert alert-success alert-dismissible"
          >
          <i className="fa fa-info-circle"></i>&nbsp; {statusMessagesReducer?.message}
          </div>
        )}
        {isSuccess ? (
          <div
            id="successMsgDiv"
            className="alert alert-success alert-dismissible"
          >
          <i className="fa fa-info-circle"></i>&nbsp;  {successMsg}
          </div>
        ) : (
          <React.Fragment />
        )}
        <div className="card pb-0 mb-0 bg-hide">
          <div className="card-header p-0 m-b-5">
            <div className="row p-relative">
              <div className="col-xs-12 col-sm-8">
                <ol className="breadcrumb custom-breadcrumb pb-0 pl-0 pt-0">
                  <button
                    className="btn btn-primary waves-effect bck-btn"
                    onClick={onBackClick}
                    style={{
                      display: currentPathArr.filter((i) => i != "").length
                        ? "inline-block"
                        : "none",
                    }}
                  >
                    <i className="fa fa-arrow-left"></i>Back
                  </button>

                  <li
                    className={`breadcrumb-item ${
                      !documentPath.length ? "document_landing" : ""
                    }`}
                    style={{
                      marginLeft: currentPathArr.filter((i) => i != "").length
                        ? "28px"
                        : "0",
                    }}
                  >
                    <span
                      className={`${
                        currentPathArr.filter((i) => i != "").length
                          ? "active"
                          : "inactive"
                      } `}
                      onClick={() => props.history.push(`/document/manage`)}
                    >
                      Documents
                    </span>
                  </li>

                  {documentPath.map((item, ind) => {
                    return (
                      <li key={ind} className="breadcrumb-item">
                        <span
                          className={
                            ind === currentPath.length - 1 ? "last-item" : ""
                          }
                          onClick={(e) =>
                            ind !== currentPath.length - 1 &&
                            onIconClick(e, item)
                          }
                        >
                          {item.name}
                        </span>
                      </li>
                    );
                  })}
                </ol>
              </div>

              <div className="col-xs-12 col-sm-4 text-right">
                {(props.userRoleId == 1 || props.userRoleId == 2) && (
                  <button
                    id="addBtn"
                    onClick={() => props.openForm()}
                    className="btn  btn-primary waves-effect bck-btn mr-1"
                  >
                    <i className="addFolder zmdi zmdi-plus"></i> Add Folder
                  </button>
                )}
                <form id="file-upload" style={{ display: "none" }}>
                  <input
                    id="uploadHiddenBtn"
                    type="file"
                    name="file"
                    onChange={handleFileUpload}
                    ref={fileInput}
                    multiple
                  />
                  <input type="submit" value="Upload" />
                </form>
                <label
                  htmlFor="uploadHiddenBtn"
                  id="uploadBtn"
                  title="Please upload excel, pdf, powerpoint or word files only"
                  className="btn  btn-primary waves-effect bck-btn"
                >
                  <i className=" fa fa-cloud-upload"></i> Upload
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAlert ? (
        <Modal>
          <div className="deleteDialog alertDialog">
            <div className="deleteDialogTitle">
              <span id="ui-id-1" className="ui-dialog-title">
                Alert!
              </span>
            </div>
            <div id="dialog-confirm" className="ui-dialog-content">
              <p>
                <span id="dialog-message">{fileMessage}</span>
              </p>
            </div>
            <div className="ui-dialog-buttonpane">
              <div className="ui-dialog-buttonset">
                <button type="button" onClick={() => setShowAlert(false)}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default NavigationSection;
