import { nanoid } from "nanoid";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  VERSION,
  IS_AUTHORIZED,
  ROLE_ID,
  LOGGED_IN_USER_ID,
  LIST_CONTENT,
  STATUS_MSG,
  SET_CURRENT_PATH,
  LIST_FOLDERS,
  SET_CURRENT_PATH_OBJ,
  isFETCHING_DATA,
  FETCHING_COMPLETED,
  isFETCHING_DATA_FOLDERS,
  FETCHING_COMPLETED_FOLDERS,
  GENERATE_STATE_AND_NONCE,
  RECEIVE_PROFILE_DATA,
  RECEIVE_ACCESS_TOKEN,
  RECEIVE_ID_TOKEN,
  CLEAR_ALL,
  IS_TOKEN_EXPIRED,
  IS_MOVE_STATUS,
  CLEAR_STATUS,
  USER_LIST,
  ROLE_LIST,
  EDIT_USER_STATUS,
  CLEAR_MSG,
} from "../action/type";

const isFetching = (state = false, action) => {
  switch (action.type) {
    case isFETCHING_DATA:
      return true;
    case FETCHING_COMPLETED:
      return false;
    default:
      return state;
  }
};

const isFetchingFolder = (state = false, action) => {
  switch (action.type) {
    case isFETCHING_DATA_FOLDERS:
      return true;
    case FETCHING_COMPLETED_FOLDERS:
      return false;
    default:
      return state;
  }
};

const isMoveFileFolder = (
  state = { statusMsg: "", isSuccess: false },
  action
) => {
  switch (action.type) {
    case IS_MOVE_STATUS:
      return {
        ...state,
        statusMsg: action.payload.body,
        isSuccess: action.payload.statusCode == 200 ? true : false,
      };
    case CLEAR_STATUS:
      return {
        ...state,
        statusMsg: "",
        isSuccess: false,
      };
    default:
      return state;
  }
};

const listContent = (state = [], action) => {
  switch (action.type) {
    case LIST_CONTENT:
      return action.payload;
    case CLEAR_ALL:
      return [];
    default:
      return state;
  }
};

const listFolders = (state = null, action) => {
  switch (action.type) {
    case LIST_FOLDERS:
      return action.payload;
    case CLEAR_ALL:
      return null;
    default:
      return state;
  }
};

const currentPath = (state = "/", action) => {
  switch (action.type) {
    case SET_CURRENT_PATH:
      return action.payload;
    case CLEAR_ALL:
      return "/";
    default:
      return state;
  }
};

const currentPathObj = (state = [], action) => {
  switch (action.type) {
    case SET_CURRENT_PATH_OBJ:
      return action.payload;
    case CLEAR_ALL:
      return [];
    default:
      return state;
  }
};

const version = (state = "", action) => {
  switch (action.type) {
    case VERSION:
      return action.payload;
    case CLEAR_ALL:
      return "";
    default:
      return state;
  }
};

const tokenState = {
  accessToken: null,
  idToken: null,
  state: null,
  nonce: null,
  isAuthorized: null,
  roleID: 0,
};

const tokenReducer = (reduxState = tokenState, { type, payload }) => {
  switch (type) {
    case RECEIVE_ACCESS_TOKEN:
      return {
        ...reduxState,
        accessToken: payload.accessToken,
      };
    case RECEIVE_ID_TOKEN:
      return {
        ...reduxState,
        idToken: payload.idToken,
      };
    case GENERATE_STATE_AND_NONCE:
      return {
        ...reduxState,
        // no need to redefine if it exists in the state already
        state: reduxState.state || nanoid(32),
        nonce: reduxState.nonce || nanoid(32),
      };
    case IS_AUTHORIZED:
      return {
        ...reduxState,
        isAuthorized: payload,
      };
    case ROLE_ID:
      return {
        ...reduxState,
        roleID: payload,
      };
    case LOGGED_IN_USER_ID:
      return {
        ...reduxState,
        userID: payload,
      };

    case CLEAR_ALL:
      return tokenState;
    default:
      return reduxState;
  }
};

const profileReducer = (state = null, { type, payload }) => {
  switch (type) {
    case RECEIVE_PROFILE_DATA:
      return {
        email: payload.profile.email,
        fullname: payload.profile.name,
        username: payload.profile.preferred_username,
        company: payload.profile.company,
        dept: payload.profile.department,
        title: payload.profile.title,
      };
    case CLEAR_ALL:
      return null;
    default:
      return state;
  }
};

const statusMessages = (state = { message: ''}, { type, payload }) => {
  switch (type) {
    case STATUS_MSG:
      return {...state, message: payload };
    case CLEAR_MSG:
      return {message: ''};
    default:
      return state;
  }
};

const tokenExpired = (state = false, { type }) => {
  switch (type) {
    case IS_TOKEN_EXPIRED:
      return true;
    case CLEAR_ALL:
      return false;
    default:
      return state;
  }
};

const userManagement = (
  state = { userList: [], roleList: [], editMsg: "" },
  { type, payload }
) => {
  switch (type) {
    case USER_LIST:
      return { userList: payload };
    case ROLE_LIST:
      return { roleList: payload };
    case EDIT_USER_STATUS:
      return { editMsg: payload };
    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  isFetchingFolder,
  isMoveFileFolder,
  listContent,
  currentPath,
  listFolders,
  currentPathObj,
  statusMessages,
  version,
  token: tokenReducer,
  profile: profileReducer,
  tokenExpired,
  userManagement,
});
