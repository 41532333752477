import React from "react";
import { connect } from "react-redux";
import {
  Redirect
} from "react-router-dom";
import { parse } from "query-string";
import jwtDecode from "jwt-decode";
import isEmpty from "lodash/isEmpty";
import Loader from "./Loader";

import { receiveAccessToken, receiveIdToken } from "../action";
import { receiveProfileData } from "../action";

const AuthPage = ({
  location,
  profile,
  receiveProfile,
  receiveTokens
}) => {
  if (isEmpty(profile)) {
    const hash = location.hash;
    const response = parse(hash);
    if (response.error) {
      alert(response.error_description);
      return <Redirect to="/home" />
    } else {
      receiveTokens(response.access_token, response.id_token);
      receiveProfile(jwtDecode(response.id_token));
      let profilData = jwtDecode(response.id_token);
    }
    // Render loader
    return <Loader />;
  } else {
    return <Redirect to="/document/manage" />
  }
};

const mapStateToProps = state => ({
  profile: state.profile
});

const mapDispatchToProps = dispatch => ({
  receiveProfile: data => dispatch(receiveProfileData(data)),
  receiveTokens: (accessToken, idToken) => {
    dispatch(receiveAccessToken(accessToken));
    dispatch(receiveIdToken(idToken));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthPage);
