import { stringify } from 'query-string';

// Static oidc params for a single provider
const authority = process.env.REACT_APP_AUTHORITY
const client_id = process.env.REACT_APP_CLIENT_ID
const redirect_uri = process.env.REACT_APP_REDIRECT_URI;
const response_type = 'id_token token';
const scope = 'openid profile params';
// const scope = 'openid profile email';

export const beginAuth = ({ state, nonce }) => {
  // Generate authentication URL
  const params = stringify({
    client_id,
    redirect_uri,
    response_type,
    scope,
    state,
    nonce,
  });
  const authUrl = `${authority}/auth?${params}`;

  // Attempt login by navigating to authUrl
  window.location.assign(authUrl);
};
