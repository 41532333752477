import './App.css';
import Header from '../src/components/Header/Header'
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Documents from "../src/components/Documents/Documents";
import ProtectedRoute from '../src/components/ProtectedRoute';
import LoginPage from '../src/components/LoginPage'
import AuthPage from './components/AuthPage'
import Default from './components/Default'
import { UserManagement } from './components/UserManagement/UserManagement';
import { EditUsers } from './components/UserManagement/EditUsers';

function App() {
  
  const loggedIn = useSelector(state => !!state.token.accessToken);

  return (
    <div className="App">
      <Router>
      <Header loggedIn={loggedIn}/>
      
        <div className='App-page'>
          <Switch>
            <ProtectedRoute
              component={LoginPage}
              open={!loggedIn}
              path='/login'
              redirect='/document/manage'
            />
            <ProtectedRoute
              component={Documents}
              open={loggedIn}
              path='/document/manage'
              redirect='/login'
            />
            <ProtectedRoute
              component={UserManagement}
              open={loggedIn}
              path='/user/manage'
              redirect='/login'
            />
            <ProtectedRoute
              component={EditUsers}
              open={loggedIn}
              path='/user/edit/:userId'
              redirect='/login'
            />
            <Route component={AuthPage} path='/auth' />
            <Route exact path='/'>
              <Redirect to="/login" />
            </Route>
            
            <Route path='/logout' 
              component={() => { window.location = `${process.env.REACT_APP_AUTHORITY}/logout`; return null;} }/>
            <Route component={ Default } />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;

// export default App;
