import { S3 } from "../aswConfig/index";
import {
  LIST_CONTENT,
  SET_CURRENT_PATH,
  LIST_FOLDERS,
  SET_CURRENT_PATH_OBJ,
  isFETCHING_DATA,
  FETCHING_COMPLETED,
  isFETCHING_DATA_FOLDERS,
  FETCHING_COMPLETED_FOLDERS,
  GENERATE_STATE_AND_NONCE,
  RECEIVE_ACCESS_TOKEN,
  RECEIVE_ID_TOKEN,
  RECEIVE_PROFILE_DATA,
  CLEAR_ALL,
  STATUS_MSG,
  IS_AUTHORIZED,
  ROLE_ID,
  LOGGED_IN_USER_ID,
  VERSION,
  IS_TOKEN_EXPIRED,
  IS_MOVE_STATUS,
  ROLE_LIST,
  USER_LIST,
  UPLOAD_DELETE_STATUS_MSG,
  CLEAR_MSG,
} from "./type";
import { axiosInstance } from "../api/edApi";
import { store } from "../store";

const formatDate = () => {
  let date = new Date();
  return `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${
    date.getMilliseconds() * 1000
  }`;
};
const UKFormatDate = () => {
  return (
    new Date()
      .toLocaleDateString("en-GB", { timeZone: "Europe/London" })
      .split("/")
      .reverse()
      .join("-") +
    " " +
    new Date().toLocaleTimeString("en-GB", { timeZone: "Europe/London" })
  );
};

export const getListContent = (id) => async (dispatch, getState) => {
  dispatch({ type: isFETCHING_DATA });

  try {
    let result = await axiosInstance.get(
      `/edlambda?id=${id || 0}&useremail=${getState().profile?.email}`,
      {
        headers: {
          authorizationToken: getState().token.accessToken,
        },
      }
    );

    let data = result?.data?.body?.contents?.reduce((obj, item) => {
      let newObj = {};
      newObj.name = item.keyName;
      newObj.date = item.type == "file" ? item.createdOn :item.modifiedOn;
      newObj.created = item.userName;
      newObj.down = ``;
      newObj.move = ``;
      newObj.delete = ``;
      newObj.type = item.type;
      newObj.itemId = item.id;
      newObj.path = item.path;
      newObj.userEmail = item.userEmail;
      newObj.userId = item.userid;
      newObj.parentDir = item.parentDir;
      newObj.isEmpty = item.isEmpty;
      obj.push(newObj);
      return obj;
    }, []);

    let path =
      result?.data.body?.folderPath
        ?.map((i) => {
          return i.name;
        })
        .reverse() || [];

    dispatch({
      type: LIST_CONTENT,
      payload: data || [],
    });
    dispatch({
      type: SET_CURRENT_PATH,
      payload: path.join("/"),
    });
    dispatch({
      type: SET_CURRENT_PATH_OBJ,
      payload: result?.data.body?.folderPath || [],
    });

    // if user email id exist in backend db => so that user can create folder and files and modify folder data
    dispatch({
      type: IS_AUTHORIZED,
      payload: result?.data.isAuthorized,
    });
    dispatch({
      type: ROLE_ID,
      payload: result?.data.roleID,
    });
    dispatch({
      type: LOGGED_IN_USER_ID,
      payload: result?.data.userID,
    });
    dispatch({
      type: VERSION,
      payload: result?.data.body?.version,
    });

    setTimeout(() => dispatch({ type: CLEAR_MSG }), 2000);
  } catch (err) {
    if (err.response?.status === 403) {
      // const confirmWin = window.confirm(`Application idle time is exceeded, Do you want to continue with new session?`)
      // if(confirmWin) dispatch({type: CLEAR_ALL})
      dispatch({ type: IS_TOKEN_EXPIRED });
      setTimeout(() => dispatch({ type: CLEAR_ALL }), 3000);
    }
    if (err.response?.status === 401) alert(err.response.data.message);
  }
  dispatch({ type: FETCHING_COMPLETED });
};

export const createNewFolder = (item, id) => async (dispatch, getState) => {
  dispatch({ type: isFETCHING_DATA });
  let contents = await S3.putObject({
    Key: item.replace(/\s+/g, " ").trim(),
    Metadata: {
      createdBy: getState().profile.email,
      createdOn: UKFormatDate(), //prev date format : new Date().toISOString().split("T").join(" "),
      type: "folder",
      prid: id,
    },
  }).promise();
  if (contents) {
    dispatch({
      type: STATUS_MSG,
      payload: "Folder created successfully",
    });
  }
  contents.$response.httpResponse.statusCode === 200 &&
    setTimeout(() => { 
      dispatch(getListContent(id))
    }, 3000);
};

export const createNewFile =
  (item, id, files) => async (dispatch, getState) => {
    dispatch({ type: isFETCHING_DATA });
    let params = Array.from(files).map(async (file) => {
      let fileName = file.name;
      let fileExt = fileName.split(".").pop();
      let result = await S3.putObject({
        Key: item + fileName,
        Body: file,
        Metadata: {
          createdBy: getState().profile.email,
          createdOn: UKFormatDate(), // prev date format used ::  new Date().toISOString().split("T").join(" "),
          type: "file",
          fileType: fileExt,
          prid: id,
        },
      }).promise();
      return result;
    });
    let contents = await Promise.all(params);
    if (contents) {
      dispatch({
        type: STATUS_MSG,
        payload: "File uploaded successfully",
      });
      setTimeout(() => dispatch(getListContent(id)), 3000);
    }
  };

export const deleteFile = (item) => async (dispatch, getState) => {
  dispatch({ type: isFETCHING_DATA });
  let res = await axiosInstance.delete(
    `/edlambda?id=${item.id}&type=${item.type}&prid=${item.parentDir}`,
    { headers: { authorizationToken: getState().token.accessToken } }
  );
  if (res?.data?.statusCode == 200) {
    dispatch({
      type: STATUS_MSG,
      payload: "Selected item deleted successfully",
    });
    setTimeout(() => dispatch(getListContent(item.parentDir)), 3000);
  }
};

export const downloadFile = (item) => async (dispatch) => {
  let fName = item.split("/");
  fName = fName[fName.length - 1];
  S3.getSignedUrl(
    "getObject",
    { Key: item, Expires: 10000 },
    function (err, url) {
      let link = document.createElement("a");
      link.setAttribute("download", fName);
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  );
};

export const moveFile =
  (targetId, type, docId, parentDir) => async (dispatch, getState) => {
    let res = await axiosInstance.put(
      `/edmovedocument?docid=${docId}&type=${type}&targetid=${targetId}&sourceprid=${parentDir}&modifiedby=${
        getState().profile.email
       }&modifiedon=${UKFormatDate()}`,
      // }&modifiedon=${new Date().toISOString().split("T").join(" ")}`,
      {},
      { headers: { authorizationToken: getState().token.accessToken } }
    );
    dispatch({ type: IS_MOVE_STATUS, payload: res.data });
  };

export const getPopupContent = () => async (dispatch, getState) => {
  dispatch({ type: isFETCHING_DATA_FOLDERS });
  let result = await axiosInstance.get(`/edmovedocument`, {
    headers: { authorizationToken: getState().token.accessToken },
  });
  dispatch({
    type: LIST_FOLDERS,
    payload: result.data?.body?.contents || [],
  });
  const value = getState().listFolders;
  value.length && dispatch({ type: FETCHING_COMPLETED_FOLDERS });
};

export const generateStateAndNonce = () => ({
  type: GENERATE_STATE_AND_NONCE,
});

export const receiveAccessToken = (accessToken) => ({
  type: RECEIVE_ACCESS_TOKEN,
  payload: { accessToken },
});

export const receiveIdToken = (idToken) => ({
  type: RECEIVE_ID_TOKEN,
  payload: { idToken },
});

export const receiveProfileData = (profile) => ({
  type: RECEIVE_PROFILE_DATA,
  payload: { profile },
});

export const logout = () => ({
  type: CLEAR_ALL,
});

export const getUsers = async (state) => {
  const storeState = store.getState();
  try {
    let res = await axiosInstance.get("/edusers", {
      headers: {
        authorizationToken: storeState.token.accessToken,
      },
    });
    return res.data;
  } catch (err) {
    if (err.response?.status === 403) {
      return err.response;
      // dispatch({ type: IS_TOKEN_EXPIRED })
      // setTimeout(()=> dispatch({type: CLEAR_ALL}), 3000)
    }
    if (err.response?.status === 401) alert(err.response.data.message);
    return err;
  }
};

export const updateUserDetail = async (data) => {
  const storeState = store.getState();
  try {
    let res = await axiosInstance.put("/edusers", data, {
      headers: {
        authorizationToken: storeState.token.accessToken,
      },
    });
    return res;
  } catch (err) {
    if (err.response?.status === 403) {
      return err.response;
      //   // dispatch({ type: IS_TOKEN_EXPIRED })
      //   // setTimeout(()=> dispatch({type: CLEAR_ALL}), 3000)
    }
    if (err.response?.status === 401) alert(err.response.data.message);
    return err;
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
