import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { parse } from "query-string";
import { getListContent } from "../../action";
import NavigationSection from "./NavigationSection";
import TableSection from "./TableSection";
import CreateFolder from "./CreateFolder";
import Loader from "../Loader";
import Modal from "../Modal/Modal";
import "./documents.css";

const Documents = (props) => {
  const [showForm, setShowForm] = useState(false);
  const listContent = useSelector((state) => state.listContent);
  const isAuthorized = useSelector((state) => state.token.isAuthorized);
  const userRoleId = useSelector((state) => state.token.roleID);
  const isFetching = useSelector((state) => state.isFetching);
  const statusMsg = useSelector((state) => state.statusMsg);
  const tokenExpired = useSelector((state) => state.tokenExpired);
  const dispatch = useDispatch();

  let columns = [
    { Header: "Name", accessor: "name" },
    {
      Header: "Date / Time",
      accessor: "date",
      Cell: (cellProps) => cellProps.value,
    },
    { Header: "Uploaded / Created By", accessor: "created" },
    { Header: "", accessor: "down", disableSortBy: true },
    { Header: "", accessor: "move", disableSortBy: true },
    { Header: "", accessor: "delete", disableSortBy: true },
    { Header: "", accessor: "itemId" },
    { Header: "", accessor: "type" },
    { Header: "", accessor: "path" },
    { Header: "", accessor: "parentDir" },
    { Header: "", accessor: "isEmpty" },
  ];
  const { id } = parse(props.location.search);

  useEffect(() => {
    dispatch(getListContent(id));
    closeForm(true);
  }, [id]);

  const openForm = () => {
    setShowForm(true);
  };
  const closeForm = () => {
    setShowForm(false);
  };

  if (isFetching) return <Loader />;
  if (tokenExpired)
    return (
      <Modal>
        <div className="deleteDialog alertDialog">
          <div className="deleteDialogTitle">
            <span id="ui-id-1" className="ui-dialog-title">
              Alert!
            </span>
          </div>
          <div id="dialog-confirm" className="ui-dialog-content">
            <p>
              <span id="dialog-message">
                Application idle time is exceeded, generating a new token...
              </span>
            </p>
          </div>
        </div>
      </Modal>
    );
  else
    return (
      <section id="main" className="layout-1">
        <div className="container-fluid">
          <div className="card-body">
            <div className="tab-content p-0">
              {isAuthorized ? (
                <section id="content" className="p-l-0">
                  <div className="container-fluid">
                    <NavigationSection
                      openForm={openForm}
                      history={props.history}
                      userRoleId={userRoleId}
                    />
                    <div className="card m-t-5 mb-0">
                      <div className="card-header p-15 pb-0 header-button">
                        <div className="card-body">
                          <div className="doclist-cont">
                            <div className="list-group m-b-10">
                              <div className="p-r-15 p-l-15 ">
                                {showForm && (
                                  <CreateFolder closeForm={closeForm} />
                                )}
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div
                                      id="myTable_wrapper"
                                      className="dataTables_wrapper no-footer"
                                    >
                                      {listContent && listContent.length ? (
                                        <TableSection
                                          columns={columns}
                                          data={listContent || []}
                                          closeForm={closeForm}
                                        />
                                      ) : (
                                        <div className="no-data text-center">
                                          <i className="fa fa-ban"></i>No
                                          Documents available.
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <div className="not-page text-center">
                  User is not Authorized
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
};

export default Documents;
