import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CLEAR_ALL, IS_TOKEN_EXPIRED } from "../../action/type";
import { getUsers } from "../../action";
import Loader from "../Loader";
import { useHistory } from "react-router-dom";
import Modal from "../Modal/Modal";

export const UserManagement = () => {
  const [loading, setLoading] = useState(false);
  const isAuthorized = useSelector((state) => state.token.isAuthorized);
  const userManagement = useSelector((state) => state.userManagement);
  const tokenExpired = useSelector((state) => state.tokenExpired);

  const { editMsg = "" } = userManagement;
  const [errMsg, setErrorMsg] = useState("");
  const [userList, setUserList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [pageData, setPageData] = useState({
    totalpage: "",
    currentPage: 1,
    numberOfItems: 13,
  });

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setUserList(userManagement.userList);
    setRoleList(userManagement.roleList);
  }, [userManagement.userList, userManagement.roleList]);

  useEffect(async () => {
    setLoading(true);
    let response = await getUsers();
    if (response.statusCode == 200) {
      let pageDataTemp = JSON.parse(JSON.stringify(pageData));
      pageDataTemp.totalpage = Math.ceil(
        response.body.users.length / pageDataTemp.numberOfItems
      );
      setPageData(pageDataTemp);
      setUserList(response.body.users);
      setRoleList(response.body.roles);
    } else if (response?.status === 403) {
      dispatch({ type: IS_TOKEN_EXPIRED });
      setTimeout(() => dispatch({ type: CLEAR_ALL }), 3000);
    } else {
      setErrorMsg(response.statusMessage);
    }
    setLoading(false);
  }, []);

  const onSelectPageHandler = (e) => {
    let pageDataTemp = JSON.parse(JSON.stringify(pageData));
    if (e.target.value <= pageDataTemp.totalpage) {
      pageDataTemp.currentPage = e.target.value ? parseInt(e.target.value) : "";
    } else {
      pageDataTemp.currentPage = 4;
    }
    setPageData(pageDataTemp);
  };

  const onEditHandler = (e, user) => {
    history.push(`/user/edit/${user.id}`, { user, roleList: roleList });
  };
  const nextPrevChangeHandler = (key) => {
    let pageDataTemp = JSON.parse(JSON.stringify(pageData));
    if (key == "next") {
      pageDataTemp.currentPage = pageDataTemp.currentPage + 1;
    } else {
      pageDataTemp.currentPage = pageDataTemp.currentPage - 1;
    }

    setPageData(pageDataTemp);
  };

  const lastFirstpageHandler = (type) => {
    let pageDataTemp = JSON.parse(JSON.stringify(pageData));
    if (type == "last") {
      pageDataTemp.currentPage = pageDataTemp.totalpage;
    } else {
      pageDataTemp.currentPage = 1;
    }
    setPageData(pageDataTemp);
  };

  if (!isAuthorized) {
    return <div className="not-page text-center">User is not Authorized</div>;
  } else if (tokenExpired) {
    return (
      <Modal>
        <div className="deleteDialog alertDialog">
          <div className="deleteDialogTitle">
            <span id="ui-id-1" className="ui-dialog-title">
              Alert!
            </span>
          </div>
          <div id="dialog-confirm" className="ui-dialog-content">
            <p>
              <span id="dialog-message">
                Application idle time is exceeded, generating a new token...
              </span>
            </p>
          </div>
        </div>
      </Modal>
    );
  } else if (loading) {
    return <Loader />;
  }
  return (
    <div id="main" className="user-list layout-1">
      <div className="container-fluid">
        <div className="card-body">
          <div className="tab-content p-0">
            <section id="content" className="p-l-0">
              <div className="container-fluid ">
                <div className="row row p-relative ">
                {editMsg?.length ? (
                    <div
                      id="successMsgDiv"
                      className="alert alert-success alert-dismissible"
                    >
                      <i className="fa fa-info-circle"></i> {editMsg}
                    </div>
                  ) : (
                    <React.Fragment />
                  )}
                  <div
                    className="col-xs-12 col-sm-8"
                    style={{ padding: "8px 15px 0px" }}
                  >
                    <span className="label_span">User List</span>{" "}
                  </div>
                  <div className="col-xs-12 col-sm-4 text-right">
                    <a
                      className="btn  btn-primary waves-effect bck-btn add-btn"
                      href="/document/manage"
                    >
                      <i
                        style={{ color: "white" }}
                        className="zmdi zmdi-long-arrow-left"
                      ></i>
                      Back
                    </a>
                  </div>
                </div>

                {/* <div id="failMsgDiv" className="alert alert-danger alert-dismissible" style="display:none;" role="alert"></div> */}
                <div className="card p-b-0 m-b-0 m-t-10">
                  <div className="card m-t-5 mb-0">
                    <div className="card-header p-15 pb-0 header-button">
                      <div className="card-body">
                        <div className="doclist-cont">
                          <div className="list-group m-b-10">
                            <div className="">
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    id="myTable"
                                    className="display dataTable dtr-inline table table-striped table-bordered electronics-table no-footer"
                                    cellSpacing="0"
                                    width="100%"
                                    role="grid"
                                    style={{ width: "100%" }}
                                    aria-describedby="myTable_info"
                                  >
                                    <thead>
                                      <tr>
                                        <th name="name" id="name">
                                          Name
                                        </th>
                                        <th name="date-time" id="date-time">
                                          User ID
                                        </th>
                                        <th
                                          name="created-uploaded-by"
                                          id="created-uploaded-by"
                                        >
                                          TOC
                                        </th>
                                        <th name="download" id="download">
                                          IsActive
                                        </th>
                                        <th name="move" id="move">
                                          Edit
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {userList
                                        ?.slice(
                                          pageData.currentPage == 0
                                            ? 0
                                            : (pageData.currentPage - 1) * 13,
                                          pageData.currentPage *
                                            pageData.numberOfItems
                                        )
                                        ?.map((user) => (
                                          <tr key={user.id}>
                                            <td className="file_name">
                                              {user.name}
                                            </td>
                                            <td>{user.email_id}</td>
                                            <td>{user.toc}</td>
                                            <td>
                                              <input
                                                className="disabled"
                                                type="checkbox"
                                                disabled="disabled"
                                                readOnly
                                                checked={
                                                  user.isActive == 1
                                                    ? "checked"
                                                    : ""
                                                }
                                              />
                                            </td>
                                            <td>
                                              <span
                                                title="Edit"
                                                onClick={(e) =>
                                                  onEditHandler(e, user)
                                                }
                                              >
                                                <i
                                                  className="fa fa-pencil-square-o"
                                                  aria-hidden="true"
                                                ></i>
                                              </span>
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                  {userList && (
                                    <div className="footer-pagging-cont">
                                      <div
                                        style={{ textAlign: "center" }}
                                        className="paging-links"
                                      >
                                        <a
                                          id="lnkFirst"
                                          className={`float_left ${
                                            pageData.currentPage == 1
                                              ? "disabled-pagination"
                                              : null
                                          }`}
                                          onClick={() =>
                                            lastFirstpageHandler("first")
                                          }
                                          disabled={true}
                                        >
                                          <i className="firstI disabled-link fl"></i>
                                        </a>
                                        <a
                                          id="lnkPrevious"
                                          className={`float_left ${
                                            pageData.currentPage == 1
                                              ? "disabled-pagination"
                                              : null
                                          }`}
                                          onClick={() =>
                                            nextPrevChangeHandler("prev")
                                          }
                                        >
                                          <i className="pagerPrev disabled-link fl"></i>
                                        </a>
                                        <label
                                          id="Label2"
                                          className="CustPageLabelClass"
                                        >
                                          Page
                                        </label>
                                        <input
                                          onChange={onSelectPageHandler}
                                          type="number"
                                          id="txtPageNumber"
                                          value={pageData.currentPage}
                                          className="display-page"
                                          autoComplete="false"
                                        />
                                        <label
                                          id="Label1"
                                          className="CustPageLabelClass"
                                        >
                                          of {pageData.totalpage}
                                        </label>
                                        <a
                                          id="lnkNext"
                                          className={
                                            pageData.currentPage ===
                                            pageData.totalpage
                                              ? "disabled-pagination"
                                              : null
                                          }
                                          onClick={() =>
                                            nextPrevChangeHandler("next")
                                          }
                                        >
                                          <i className="pagerNext disabled-link"></i>
                                        </a>
                                        <a
                                          id="lnklast"
                                          className={
                                            pageData.currentPage ===
                                            pageData.totalpage
                                              ? "disabled-pagination"
                                              : null
                                          }
                                          onClick={() =>
                                            lastFirstpageHandler("last")
                                          }
                                        >
                                          <i className="lastI disabled-link"></i>
                                        </a>
                                        <div className="clear"></div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
